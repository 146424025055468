body {
    margin: 0;
    padding: 0;
    position: relative;
}
.fill {
    object-fit: fill;
}
.content-box {
    box-sizing: content-box;
}
.blue-grad {
    background: linear-gradient(90deg, rgba(5,1,77,0.6) 0%, rgba(71,197,223,0.6) 50%, rgba(5,1,77,0.6) 100%);
}
.red-grad {
    background: linear-gradient(90deg, rgba(154,0,44,0.4) 0%, rgba(233,44,92,0.8) 50%, rgba(154,0,44,0.4) 100%);
}
.photo-wrapper {
    background: rgba(0,17,36,0.4);
    border-radius: 4px;
}
.photo-wrapper:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
}
.center-button {
    z-index: 2; 
    bottom: 2vh;
    position: absolute;
    transform: translate(-50%, -0);
    left: 50%;
    min-height: 125px;
    min-width: 125px;
}
.switch-camera-button {
    z-index: 2; 
    bottom: 2vh;
    position: absolute;
    right: 5%;
}
.reply-button {
    z-index: 2; 
    bottom: 2vh;
    position: absolute;
    left: 5%;
}
.controls-wrapper .control {
    margin-left:15px;

}
.round-button {
    min-height: 0 !important;
    min-width: 0 !important;
    padding: 15px !important;
    border-radius: 0px !important;
    font: verdana !important;
    font-size: 30px !important;
}
.tool-icon {
    color: #ffffff;
    text-shadow:
      3px 3px 0 #000,
      -3px 3px 0 #000,
      -3px -3px 0 #000,
      3px -3px 0 #000;
    opacity: 0.7;
}
.tool-icon:hover {
    opacity: 1;
    text-shadow: 0 0 30px #000;
}
.tool-icon:hover {
    opacity: 0.8;
}
.photo-button {
    font-size: 15px;
    width: 100%;
    height: 100%;
    position: absolute;
}

.circle {
    position: absolute;
    top: 12%;
    left: 12%;
    bottom: 12%;
    right: 12%;
    border-radius: 100%;
    opacity: 0;
    background-color: #ffffff;
}
.background-circle {
    position: absolute;
    top: 8%;
    left: 8%;
    border-radius: 100%;
    background-color: rgba(233 ,44, 92, 0.45);
    width:84%;
    height:84%;
}

.ring {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 100%;
    border: 0.5em solid #ffffff;
    opacity: 0.8;
}
.photo-button .circle, .photo-button .background-circle .ring .tool-icon {
    transition: all 0.25s;
}

.photo-button:hover .background-circle {
    opacity: 1;
}
.photo-button:hover .circle {
    opacity: 1;
}

.photo-button:hover .ring {
    opacity: 1;
}

.photo-button:hover .circle {
    opacity: 0.5;
}